@font-face {
  font-family: Quicksand;
  src: url(./fonts/Quicksand-VariableFont_wght.ttf);
}

body {
  color: #fff;
  margin: 0;
  overflow-x: hidden;
  font-family: Quicksand, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
